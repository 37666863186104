<template>
  <div>
    <v-autocomplete
      hide-details
      :value="value"
      clearable
      :items="propertyList"
      dense
      flat
      filled
      placeholder="Select Site Location"
      item-color="cyan"
      color="cyan"
      solo
      item-text="display_name"
      item-value="id"
      :loading="isLoading"
      :menu-props="{
        contentClass: isLoading ? 'autocomplete-dropdown-menu is-loading' : '',
      }"
      v-bind="$attrs"
      @change="selectProperty"
      @click:clear="$emit('click:clear')"
      :search-input.sync="searchInput"
      :filter="filterProperties"
    >
      <template v-slot:no-data>
        <v-list-item>
          <div
            v-if="isLoading"
            class="w-100 d-flex align-center justify-center py-2"
          >
            <v-progress-circular indeterminate color="cyan" />
          </div>
          <v-list-item-title v-else v-html="'No property(s) Found.'" />
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-home-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
          >
            {{ item.display_name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  name: "property-dropdown",
  data() {
    return {
      isLoading: false,
      propertyList: [],
      timeout: null,
      searchInput: "",
      timeoutLimit: 1000,
    };
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    customerId: {
      type: [String, Number, Object],
      default: null,
    },
  },
  watch: {
    customerId() {
      this.isLoading = true;
      this.propertyList = [];
      this.getProperties();
    },
    searchInput() {
      this.doTypingSearch();
    },
  },
  methods: {
    doTypingSearch() {
      this.isLoading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.doSearch();
      }, this.timeoutLimit);
    },
    filterProperties(item, queryText) {
      const itemText = `${item.display_name || ""}`;
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    doSearch() {
      const param = this.searchInput || "";
      if (param.length < 3) {
        this.isLoading = false;
        return;
      }
      const selectedProperty = this.propertyList.find(
        (row) => row.id === this.value
      );
      if (selectedProperty?.id && selectedProperty.display_name === param) {
        this.isLoading = false;
        return;
      }
      this.getProperties(param);
    },
    selectProperty(value) {
      this.searchInput = "";
      this.$nextTick(() => {
        this.$emit("input", value);
        this.$emit("change", value);
        const valObj = this.propertyList.find((row) => row.id === value);
        this.$emit("change:object", valObj);
      });
    },
    getProperties(search = "") {
      if (this.propertyList.length && !search && this.customerId) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: `property-list/${this.customerId ?? ""}`,
          data: { search, value: this.value },
        })
        .then(({ data }) => {
          this.propertyList = data;
        })
        .catch((error) => {
          this.$emit("error", error.message || "Failed to load properties");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!this.value || !this.propertyList.length) {
      this.getProperties();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>
