<template>
  <v-layout
    v-if="getPermission('property:create') || getPermission('property:update')"
  >
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-layout class="d-block">
          <v-row>
            <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
              <v-container fluid>
                <v-row>
                  <v-col class="pt-0" md="12" sm="12">
                    <v-row>
                      <v-col md="7" sm="7">
                        <v-layout v-if="false">
                          <v-flex class="mr-2">
                            <v-switch
                              v-model="trackGPS"
                              class="mt-0"
                              inset
                              label="Get your current location"
                              v-on:change="getUserCurrentAddress"
                              color="cyan"
                            ></v-switch>
                          </v-flex>
                          <!--<v-flex v-if="!isCustomerFrom">
                            <v-switch
                              v-model="propertyAddress.is_tenant"
                              class="mt-0"
                              inset
                              :true-value="1"
                              :false-value="0"
                              label="Is Tenant"
                              color="cyan"
                            ></v-switch>
                          </v-flex> -->
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12" class="mb-4" sm="12">
                    <template v-if="propertyAddress.is_tenant">
                      <v-row>
                        <v-col md="12">
                          <v-layout>
                            <v-flex>
                              <p class="m-0 font-size-16">
                                <label
                                  class="mr-2 required font-weight-500 text-capitalize"
                                  >Full Name
                                </label>
                                <template v-if="tenant.first_name">
                                  <span class="text-capitalize"
                                    >{{ tenant.title }}.
                                    {{ tenant.first_name }}
                                    {{ tenant.last_name }}</span
                                  >
                                </template>
                                <em v-else class="text--secondary"
                                  >No Full Name</em
                                >
                              </p>
                              <p class="m-0 font-size-16">
                                <label
                                  class="mr-2 required font-weight-500 text-capitalize"
                                  >Display Name
                                </label>
                                <template v-if="tenant.display_name">
                                  {{ tenant.display_name }}
                                </template>
                                <em v-else class="text--secondary"
                                  >No Display Name</em
                                >
                              </p>
                              <p class="m-0 font-size-16">
                                <label
                                  class="mr-2 required font-weight-500 text-capitalize"
                                  >Phone Number
                                </label>
                                <template v-if="tenant.primary_phone">
                                  {{ tenant.primary_phone }}
                                </template>
                                <em v-else class="text--secondary"
                                  >No Phone Number</em
                                >
                              </p>
                              <p class="m-0 font-size-16">
                                <label class="mr-2 required font-weight-500"
                                  >Email
                                </label>
                                <template v-if="tenant.primary_email">
                                  <span class="text-lowercase">{{
                                    tenant.primary_email
                                  }}</span>
                                </template>
                                <em v-else class="text--secondary">No Email</em>
                              </p>
                            </v-flex>
                            <v-flex>
                              <v-btn
                                class="mx-2"
                                color="cyan"
                                dark
                                fab
                                x-small
                                v-on:click="dialog = true"
                              >
                                <v-icon small dark> mdi-pencil</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row v-else>
                      <v-col md="4">
                        <label class="font-size-16 font-weight-500 required"
                          >Contact Person
                        </label>
                        <template
                          v-if="contactPersons.length && propertyAddress"
                        >
                          <v-select
                            id="contact-persons"
                            v-model.trim="propertyAddress.contact_person"
                            :items="contactPersons"
                            :rules="[
                              validateRules.required(
                                propertyAddress.contact_person,
                                'Contact Person'
                              ),
                            ]"
                            dense
                            filled
                            item-text="display_name"
                            item-value="id"
                            item-color="cyan"
                            label="Contact Persons"
                            solo
                            flat
                            color="cyan"
                          ></v-select>
                        </template>
                        <template v-else>
                          <v-select
                            id="contact-persons"
                            v-model="propertyAddress.contact_person"
                            :items="cc_persons"
                            dense
                            filled
                            item-text="display_name"
                            item-value="uuid"
                            item-color="cyan"
                            label="Contact Persons"
                            solo
                            flat
                            :rules="[
                              validateRules.required(
                                propertyAddress.contact_person,
                                'Contact Person'
                              ),
                            ]"
                            color="cyan"
                          ></v-select>
                        </template>
                      </v-col>
                      <v-col md="4" v-if="!isCustomerFrom">
                        <label class="font-size-16 font-weight-500 w-100"
                          >&nbsp;</label
                        >
                        <v-btn
                          color="cyan white--text"
                          v-on:click="dialog = true"
                          >Add Contact Person</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500"
                      >Site Location Name</label
                    >
                    <!-- validateRules.required(
                          propertyAddress.property_name,
                          'Site Location Name'
                        ), -->
                    <v-text-field
                      v-model.trim="propertyAddress.property_name"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.property_name,
                          'Site Location Name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Site Location Name"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500 required"
                      >Country
                    </label>
                    <v-autocomplete
                      :items="countryList"
                      v-model.trim="propertyAddress.country"
                      hide-details
                      flat
                      :rules="[
                        validateRules.required(
                          propertyAddress.country,
                          'Country'
                        ),
                      ]"
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      label="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="4" sm="12">
                    <label class="font-size-16 font-weight-500"
                      >Search Address
                    </label>
                    <SearchAddress
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="propertyAddress.country"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :label="
                        propertyAddress.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                      :placeholder="
                        propertyAddress.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                    />
                  </v-col>
                  <template v-if="propertyAddress.country == 'singapore'">
                    <v-col md="4" sm="12">
                      <label class="font-size-16 font-weight-500 required">
                        <template v-if="propertyAddress.country == 'singapore'">
                          Postal Code
                        </template>
                        <template v-else> Find Address </template>
                      </label>
                      <v-text-field
                        v-model.trim="propertyAddress.zip_code"
                        :rules="[
                          validateRules.required(
                            propertyAddress.zip_code,
                            'Postal Code'
                          ),
                          validateRules.maxLength(
                            propertyAddress.zip_code,
                            'Postal Code',
                            6
                          ),
                          validateRules.minLength(
                            propertyAddress.zip_code,
                            'Postal Code',
                            2
                          ),
                        ]"
                        dense
                        filled
                        v-mask="'######'"
                        label="Postal Code"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                      <PostalCodeInput
                        v-if="false"
                        required
                        variant="outlined"
                        density="compact"
                        hide-details
                        :country="propertyAddress.country"
                        :country-code="country_code"
                        v-model="propertyAddress.zip_code"
                        @update:address="(data) => setAddress(data)"
                        :placeholder="
                          propertyAddress.country == 'singapore'
                            ? 'Postal Code'
                            : 'Find Address'
                        "
                      />
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col
                      md="6"
                      sm="6"
                      v-if="propertyAddress.country != 'singapore'"
                    >
                      <label class="font-size-16 font-weight-500 required"
                        >Full Address</label
                      >
                      <v-textarea
                        v-model.trim="propertyAddress.street_1"
                        :rules="[
                          validateRules.required(
                            propertyAddress.street_1,
                            'Full Address'
                          ),
                          validateRules.maxLength(
                            propertyAddress.street_1,
                            'Full Address',
                            255
                          ),
                        ]"
                        auto-grow
                        dense
                        filled
                        label="Full Address"
                        solo
                        flat
                        row-height="15"
                        color="cyan"
                      ></v-textarea>
                      <!-- <v-text-field
                        v-model.trim="propertyAddress.street_1"
                        :rules="[
                          validateRules.required(
                            propertyAddress.street_1,
                            'Full Address'
                          ),
                          validateRules.maxLength(
                            propertyAddress.street_1,
                            'Full Address',
                            255
                          ),
                        ]"
                        dense
                        filled
                        label="Full Address"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field> -->
                    </v-col>
                    <v-col
                      md="4"
                      sm="12"
                      v-if="propertyAddress.country == 'singapore'"
                    >
                      <label class="font-size-16 font-weight-500 required"
                        >Postal Code</label
                      >
                      <v-text-field
                        v-model.trim="propertyAddress.zip_code"
                        :rules="[
                          validateRules.required(
                            propertyAddress.zip_code,
                            'Postal Code'
                          ),
                          validateRules.maxLength(
                            propertyAddress.zip_code,
                            'Postal Code',
                            6
                          ),
                          validateRules.minLength(
                            propertyAddress.zip_code,
                            'Postal Code',
                            2
                          ),
                        ]"
                        dense
                        filled
                        v-mask="'######'"
                        label="Postal Code"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-col
                    md="4"
                    sm="12"
                    v-if="propertyAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500">Unit No.</label>
                    <v-text-field
                      v-model.trim="propertyAddress.unit_no"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.unit_no,
                          'Unit No.',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Unit No."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    sm="12"
                    v-if="propertyAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500 required"
                      >Address Line 1</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.street_1"
                      :rules="[
                        validateRules.required(
                          propertyAddress.street_1,
                          'Address Line 1'
                        ),
                        validateRules.maxLength(
                          propertyAddress.street_1,
                          'Address Line 1',
                          255
                        ),
                      ]"
                      dense
                      filled
                      label="Address Line 1"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    sm="12"
                    v-if="propertyAddress.country == 'singapore'"
                  >
                    <label class="font-size-16 font-weight-500"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="propertyAddress.street_2"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.street_2,
                          'Address Line 2',
                          255
                        ),
                      ]"
                      dense
                      filled
                      label="Address Line 2"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-none" md="4" sm="12">
                    <label>Latitude</label>
                    <v-text-field
                      v-model.trim="propertyAddress.latitude"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.latitude,
                          'Latitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Latitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none" md="4" sm="12">
                    <label>Longitude</label>
                    <v-text-field
                      v-model.trim="propertyAddress.longitude"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.longitude,
                          'Longitude',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Longitude"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="6">
                    <label class="font-size-16 font-weight-500"
                      >Remark For Address</label
                    >
                    <v-textarea
                      v-model.trim="propertyAddress.remarks"
                      :rules="[
                        validateRules.maxLength(
                          propertyAddress.remarks,
                          'Remarks',
                          512
                        ),
                      ]"
                      auto-grow
                      dense
                      filled
                      label="Remarks"
                      solo
                      flat
                      row-height="15"
                      v-on:keypress="(e) => manageLimitdescr(e)"
                      v-on:paste="(e) => onPaste(e)"
                      color="cyan"
                    ></v-textarea>
                    <div style="text-align: end">
                      {{
                        propertyAddress.remarks
                          ? propertyAddress.remarks.length
                          : 0
                      }}/200
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-layout>
      </perfect-scrollbar>
    </v-col>
    <CreateContactPerson
      do-save
      :customer-id="customerId"
      :is-tenant="!!+propertyAddress.is_tenant"
      v-model="contactPersons"
      :person-dialog="dialog"
      v-on:close="dialog = false"
      v-on:update:person="getContactPerson"
      v-on:update:tenant="updateTenant"
      :disableOne="true"
      :address-customer="propertyCustomer"
    ></CreateContactPerson>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PostalCodeInput from "@/view/components/PostalCodeInput";
import SearchAddress from "@/view/components/Search-Address";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { QUERY, GET } from "@/core/services/store/request.module";
import CreateContactPerson from "@/view/pages/customer/create-or-update/Create-Contact-Person";

export default {
  name: "property-address",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultPosition: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    propertyData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Object,
      default: function () {
        return {};
      },
    },
    property: {
      type: Number,
      default: 0,
    },
    propertyCustomer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isCustomerFrom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactPersons: [],
      customerId: null,
      personId: null,
      dialog: false,
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      country_code: "sg",
      autocompleteList: new Array(),
      searchAddress: null,
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      tenant: {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
      },
      propertyAddress: {
        id: null,
        type: 1,
        is_same: 0,
        is_tenant: 0,
        unit_no: null,
        contact_person: null,
        property_name: null,
        street_1: null,
        doUpdate: 0,
        street_2: null,
        country: "singapore",
        zip_code: null,
        latitude: null,
        longitude: null,
        remarks: null,
      },
      fieldDescriptions: [
        /*{
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }*/
      ],
    };
  },
  watch: {
    lead: {
      deep: true,
      immediate: true,
      handler() {
        this.getLead();
      },
    },
    "propertyAddress.is_same"() {
      this.isSameProperty();
    },

    propertyAddress: {
      deep: true,
      immediate: true,
      handler(param) {
        param.latitude = param.latitude ? param.latitude.toString() : null;
        param.longitude = param.longitude ? param.longitude.toString() : null;
        this.$emit("savePropertyAddress", param);
      },
    },
    defaultPosition: {
      deep: true,
      handler(param) {
        if (param.lat && param.lng && !param.custom) {
          this.getStreetAddressFrom(param.lat, param.lng);
        }
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.propertyAddress.remarks = param.remarks;
        }
      },
    },

    propertyData: {
      deep: true,
      handler(param) {
        const _this = this;
        _this.isSameProperty();
        if (_this.lodash.isEmpty(param) === false) {
          _this.propertyAddress.id = param.id;
          _this.propertyAddress.type = param.type;
          _this.propertyAddress.contact_person = param.contact_person.id;
          _this.propertyAddress.is_same = param.is_same;
          _this.propertyAddress.is_tenant = param.is_tenant;
          _this.propertyAddress.unit_no = param.unit_no;
          _this.propertyAddress.street_1 = param.street_1;
          _this.propertyAddress.street_2 = param.street_2;
          _this.propertyAddress.zip_code = param.zip_code;
          _this.propertyAddress.property_name = param.property_name;
          (_this.propertyAddress.country = param.country
            ? param.country
            : "singapore"),
            /* _this.lodash.isEmpty(param.country) === false
              ? param.country.name
              : "Singapore"; */
            (_this.propertyAddress.latitude = param.latitude);
          _this.propertyAddress.longitude = param.longitude;
          _this.propertyAddress.remarks = param.remarks;
          if (param.is_tenant) {
            this.updateTenant(param.contact_person);
          }
        }
      },
    },
    cc_persons: {
      deep: true,
      handler() {
        this.propertyAddress.contact_person = null;
      },
    },
  },
  methods: {
    /*  manageLimit(e) {
      if (
        this.propertyAddress.remarks &&
        this.propertyAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    }, */
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;

      this.propertyAddress.zip_code = null;
      this.propertyAddress.street_1 = null;
      this.propertyAddress.street_2 = null;
      this.propertyAddress.unit_no = null;
      this.propertyAddress.longitude = null;
      this.propertyAddress.latitude = null;
    },
    setAddress(e) {
      this.propertyAddress.zip_code = e.postal_code;
      this.propertyAddress.street_1 = e.address1;
      this.propertyAddress.street_2 = e.address2;
      this.propertyAddress.unit_no = e.unit_number;
      this.propertyAddress.longitude = e.longitude;
      this.propertyAddress.latitude = e.latitude;
    },
    getCountryList() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/get-country" })
        .then(({ data }) => {
          _this.countryList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.propertyAddress.remarks;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.propertyAddress.remarks = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.propertyAddress.remarks &&
        this.propertyAddress.remarks.length > 199
      ) {
        e.preventDefault();
      }
    },

    updateTenant(row) {
      this.propertyAddress.contact_person = row.primary_email;
      this.tenant = new Object({
        id: row.id,
        title: row.title,
        first_name: row.first_name,
        last_name: row.last_name,
        display_name: row.display_name,
        primary_email: row.primary_email,
        primary_phone: row.primary_phone,
      });
    },
    isSameProperty() {
      const _this = this;
      if (!_this.isDialog) {
        if (
          _this.propertyAddress.is_same != 0 &&
          _this.lodash.isEmpty(_this.billing) === false
        ) {
          _this.propertyAddress.latitude = _this.billing.latitude;
          _this.propertyAddress.longitude = _this.billing.longitude;
          _this.propertyAddress.street_1 = _this.billing.street_1;
          _this.propertyAddress.street_2 = _this.billing.street_2;
          _this.propertyAddress.unit_no = _this.billing.unit_no;
          _this.propertyAddress.country = _this.billing.country;
          _this.propertyAddress.latitude = _this.billing.latitude;
          _this.propertyAddress.longitude = _this.billing.longitude;
          _this.propertyAddress.zip_code = _this.billing.zip_code;
          _this.propertyAddress.property_name = _this.billing.property_name;
        } else {
          _this.propertyAddress.latitude = null;
          _this.propertyAddress.longitude = null;
          _this.propertyAddress.street_1 = null;
          _this.propertyAddress.street_2 = null;
          _this.propertyAddress.unit_no = null;
          _this.propertyAddress.country = "singapore";
          _this.propertyAddress.zip_code = null;
          _this.propertyAddress.property_name = null;
        }
      }
    },
    getLead() {
      const _this = this;
      _this.propertyAddress.is_same = 0;
      _this.propertyAddress.zip_code = _this.lead.zip;
      _this.propertyAddress.country = _this.lead.country
        ? _this.lead.country
        : "singapore";
      _this.propertyAddress.unit_no = _this.lead.unit_no;
      _this.propertyAddress.street_1 = _this.lead.address_1;
      _this.propertyAddress.street_2 = _this.lead.address_2;
      _this.propertyAddress.longitude = _this.lead.longitude;
      _this.propertyAddress.latitude = _this.lead.latitude;
    },
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.propertyAddress.latitude = null;
      _this.propertyAddress.longitude = null;
      // _this.propertyAddress.property_name = null;
      _this.propertyAddress.street_1 = null;
      _this.propertyAddress.street_2 = null;
      _this.propertyAddress.country = "singapore";
      // _this.propertyAddress.unit_no = null;
      _this.propertyAddress.zip_code = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.propertyAddress.latitude = result.latitude;
              _this.propertyAddress.longitude = result.longitude;
              // _this.propertyAddress.property_name = result.property_name;
              _this.propertyAddress.street_1 = result.street_1;
              _this.propertyAddress.street_2 = result.street_2;
              //  _this.propertyAddress.unit_no = result.unit_no;
              _this.propertyAddress.zip_code = result.zip_code;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },
    getContactPerson() {
      const _this = this;
      _this.contactPersons = [];
      if (_this.customerId) {
        _this.$store
          .dispatch(QUERY, {
            url: `contact-person-list/${_this.customerId}`,
            data: {
              "is-property": 1,
            },
          })
          .then(({ data }) => {
            _this.contactPersons = data;
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
  },
  components: {
    CreateContactPerson,
    PostalCodeInput,
    SearchAddress,
  },
  computed: {
    ...mapGetters(["cc_persons"]),
  },
  beforeMount() {
    if (!this.isCustomerFrom) {
      this.customerId = this.lodash.toSafeInteger(this.$route.query.customer);
      if (!this.customerId) {
        this.goBack();
      }
    }
  },
  mounted() {
    this.getContactPerson();
    this.isSameProperty();
    /* this.getCountryList(); */
    if (this.property > 0) {
      this.propertyAddress = this.getPropertyById(this.customer, this.property);
      this.propertyAddress.country = this.propertyAddress.country;
    }
  },
};
</script>
