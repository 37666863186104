<template>
  <div>
    <v-autocomplete
      hide-details
      :value="value"
      clearable
      :items="productList"
      dense
      flat
      filled
      placeholder="Select Product"
      item-color="cyan"
      color="cyan"
      solo
      item-text="barcode"
      item-value="id"
      :loading="isLoading"
      :menu-props="{
        contentClass: isLoading ? 'autocomplete-dropdown-menu is-loading' : '',
      }"
      v-bind="$attrs"
      @change="selectProduct"
      @click:clear="$emit('click:clear')"
      @keyup.enter="doSearch"
      :search-input.sync="searchInput"
      :filter="filterProducts"
    >
      <template v-slot:no-data>
        <v-list-item>
          <div
            v-if="isLoading"
            class="w-100 d-flex align-center justify-center py-2"
          >
            <v-progress-circular indeterminate color="cyan" />
          </div>
          <v-list-item-title v-else v-html="'No item(s) Found.'" />
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar>
          <v-img
            :lazy-src="$defaultImage"
            :src="item.primary_image"
            aspect-ratio="1"
            class="margin-auto"
            transition="fade-transition"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            >{{ item.name }}
          </v-list-item-title>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            >{{ item.barcode }}</v-list-item-title
          >
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  name: "product-dropdown",
  data() {
    return {
      isLoading: false,
      productList: [],
      searchInput: "",
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
  },
  watch: {
    searchInput() {
      this.doTypingSearch();
    },
  },
  methods: {
    doTypingSearch() {
      this.isLoading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.doSearch();
      }, this.timeoutLimit);
    },
    filterProducts(item, queryText) {
      const itemText = `${item?.name ?? ""} ${item?.serial_number ?? ""} ${
        item?.model_no ?? ""
      } ${item?.model ?? ""} ${item?.brand ?? ""} ${item?.barcode ?? ""} ${
        item?.description ?? ""
      }`;
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    doSearch() {
      const param = this.searchInput || "";
      if (param.length < 3) {
        this.isLoading = false;
        return;
      }
      const selectedProduct = this.productList.find(
        (row) => row.id === this.value
      );
      if (selectedProduct?.id && selectedProduct.barcode === param) {
        this.isLoading = false;
        return;
      }
      this.getProducts(param);
    },
    selectProduct(value) {
      this.searchInput = "";
      this.$nextTick(() => {
        this.$emit("input", value);
        this.$emit("change", value);
        const valObj = this.productList.find((row) => row.id === value);
        this.$emit("change:object", valObj);
      });
    },
    getProducts(search = "") {
      if (this.productList.length && !search) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "product/get-line-products",
          data: { search, value: this.value },
        })
        .then(({ data }) => {
          this.productList = data;
        })
        .catch((error) => {
          this.$emit("error", error.message || "Failed to load products");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!this.value || !this.productList.length) {
      this.getProducts();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>
